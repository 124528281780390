import { FC, useMemo } from 'react';
import { useFormAction } from '../../../../contexts/FormActionContext';
import { Option } from '../../../Option';
import ActionPreview from '../../ActionPreview';
import { ActionPreviewBaseProps } from '../ActionBaseProps';

const SelectListPreview: FC<ActionPreviewBaseProps<Option<string, string | number>>> = (props) => {
  const { answerOnly, response, data } = props;
  const { question, previewQuestion, previewDescription } = data;
  const { riskLevel } = useFormAction();

  const title = useMemo(() => previewQuestion || question, [previewQuestion, question]);

  if (answerOnly) {
    return <>{response?.text || '-'}</>;
  }

  return (
    <ActionPreview
      data-cy="select-list-preview"
      riskLevel={riskLevel}
      question={title}
      description={previewDescription}
      answer={response?.text || '-'}
    />
  );
};

export default SelectListPreview;
